// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-destruction-js": () => import("./../../../src/pages/data-destruction.js" /* webpackChunkName: "component---src-pages-data-destruction-js" */),
  "component---src-pages-e-waste-collection-js": () => import("./../../../src/pages/e-waste-collection.js" /* webpackChunkName: "component---src-pages-e-waste-collection-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-service-area-js": () => import("./../../../src/pages/service-area.js" /* webpackChunkName: "component---src-pages-service-area-js" */)
}

